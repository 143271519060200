<template>
  <div class="wallet-wrap">
    <div class="wallet">
      <iframe
        class="my-wallet"
        frame-border="0"
        :src="walletUrl"
        frameborder="0"
        ref="mainIframe"
      ></iframe>
    </div>
  </div>
</template>
<script>
import { env } from "@/utils";
import { publicDo } from "@/api/dado";
//创建客服群
import { createCustomerGroup } from "@/api/rest";
export default {
  data() {
    return {
      walletUrl: "",
      tabPosition: "left",
      active: "wallet",
      langcode: "1",
      userInfo: "",
    };
  },
  created() {
    this.$store.dispatch("commitMenuStatus", false);
    this.langcode = localStorage.getItem("langCode") || "1";
    let walletUrl = `${env("PAYURL")}/mepay?token=${
      this.$store.getters.userInfo.token
    }&providerId=${this.$store.getters.userInfo.id}&curLanguage=${
      this.langcode
    }`;
    // 兼容用户已在此页面，点击IM转账传值路由不会刷新的问题，用X管理器进行传值
    if (
      this.$store.state.pageOperation.transferType === "transfer" &&
      this.$store.state.pageOperation.transferId !== ""
    ) {
      this.getUserInfo(this.$store.state.pageOperation.transferId).then((e) => {
        this.walletUrl =
          walletUrl +
          `&type=${this.$store.state.pageOperation.transferType}&transferAccount=${e}`;
        this.$store.commit("setTransferType", "");
        this.$store.commit("setTransferId", "");
      });
    } else {
      this.walletUrl = walletUrl + `&type=${this.$route.params.type}`;
    }
  },
  async mounted() {
    const mapFrame = this.$refs["mainIframe"];
    this.userInfo = this.$store.getters.userInfo;
    if (mapFrame.attachEvent) {
      mapFrame.attachEvent("onload", () => {
        const iframeWin = mapFrame.contentWindow;
        iframeWin.postMessage(
          JSON.stringify(this.userInfo),
          `${env("PAYURL")}/mepay`
        );
      });
    } else {
      mapFrame.onload = () => {
        const iframeWin = mapFrame.contentWindow;
        iframeWin.postMessage(
          JSON.stringify(this.userInfo),
          `${env("PAYURL")}/mepay`
        );
      };
    }
    // 监听来自iframe的消息
    window.addEventListener("message", (event) => {
      if (event.data.code == 1) {
        this.chatContanct();
      } else if (event.data.code == 2) {
        this.$message.closeAll();
        this.$message({
          type: "error",
          message: event.data.params[0],
        });
      } else if (event.data.code == 4) {
        if (event.data.params[0].rechargeId) {
          this.$store.commit("setRechargeId", event.data.params[0].rechargeId);
        }
      } else if (event.data.code == 5) {
        this.handleClickVip();
      } else if (event.data.code == 6) {
        this.thirdPartyLogin();
      }
    });
  },
  methods: {
    // 验证第三方登录
    thirdPartyLogin() {
      if (
        this.$store.getters.userInfo.loginState === "thirdPartyLogin" &&
        !this.$store.state.accountStore.secureInfo.hasLoginStrongPwd
      ) {
        this.$confirm(this.$t("thirdPartyLoginTips"), this.$t("tips"), {
          confirmButtonText: " ",
          type: "warning",
          customClass: "melinked-tips",
          showCancelButton: false,
          confirmButtonClass: "el-icon-right my-confirm-btn",
          closeOnHashChange: true,
          closeOnPressEscape: false,
          closeOnClickModal: false,
        })
          .then((e) => {
            this.$store.commit("setAccountSecurityDialogStatus", true);
            this.$store.commit(
              "setNeedShowAccountSecurityComponentName",
              "setNewPassword"
            );
          })
          .catch((e) => {});
      }
    },
    // 获取转账对象的信息
    async getUserInfo(uid) {
      let params = {
        funcId: "hex_helome_getProfileInfoFunction",
        provider_id: uid,
        query_no_cache: 1,
        table_name_replace: localStorage.getItem("langCode"),
      };
      if (this.$store.getters.userInfo.id) {
        params.userId = this.$store.getters.userInfo.id;
      }
      let result = await publicDo(params, "public/data.do");
      if (result && result.data && result.data.length) {
        return result.data[0].uc_id;
      }
    },
    // 点击联系客服
    async chatContanct() {
      let userCatch = this.$store.getters.userInfo;
      if (userCatch.groupId) {
        /** 5.3.2创建一个新的客服群,并推送消息 */
        // 1.创建一个客服群
        const TOKEN = "BF03DF03D6925CDsf555828633A096D0C234";
        const result = await createCustomerGroup(TOKEN).catch(() => {});
        if (result.code == 200) {
          const { groupHeader, groupId, groupName } = result.data.data;
          // 2.打开IM
          setTimeout(() => {
            this.$openIM({
              id: groupId,
              displayName: groupName,
              avatar: groupHeader,
              isGroup: true,
            });
          }, 100);
        }
      }
    },
  },
};
</script>
<style lang="stylus">
@import '~styles/utils/index';
.wallet-wrap{
  width: 100%;
  height: calc(100vh - 60px);
  box-sizing: border-box;
  background: #f7f7f7;
  overflow-x: hidden;
  overflow-y: auto;
}

+b(wallet){
    width 86%;
    width 1160px;
    // min-width 1200px;
    height:calc(100vh - 80px);
    margin 0 auto
    margin-top: 10px;
    +e(tabs) {
        flex: 1;
        display: flex;

        .el-tabs__content {
        flex: 6;
        background #ffffff
        border 1px solid #e5e5e5
        }

        .el-tabs__header {
        flex: 1.2;
        display: flex;
        height: auto !important;

        .el-tabs__nav-wrap {
            flex: 1;
            border-top 1px solid #e5e5e5
            border-left 1px solid #e5e5e5
            border-bottom 1px solid #e5e5e5
        }

        .el-tabs__item {
            display: flex;
            align-items: center;
            margin: 0.5rem 0;

            i {
            float: left;
            font-size: 1.5rem;
            }
        }
        }

        .my-wallet {
        min-height: 900px;
        border: 0;
        overflow: hidden;
        width: 100%;
        height: 100%;
        }
    }

}
.my-wallet {
     min-height: 70vh
     border: 0;
     overflow: hidden;
     width: 100%;
     height: 100%;
   }
</style>
