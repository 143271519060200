var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wallet-wrap" }, [
    _c("div", { staticClass: "wallet" }, [
      _c("iframe", {
        ref: "mainIframe",
        staticClass: "my-wallet",
        attrs: { "frame-border": "0", src: _vm.walletUrl, frameborder: "0" }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }